export const routes = [
    {
        path: '/activities',
        name: 'activities.browse',
        component: () => import(/* webpackChunkName: "ActivitiesBrowse" */ '@/views/app/ActivitiesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/activities/create',
        name: 'activities.create',
        component: () => import(/* webpackChunkName: "ActivitiesActions" */ '@/views/app/ActivitiesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/activities/:id/edit',
        name: 'activities.edit',
        component: () => import(/* webpackChunkName: "ActivitiesActions" */ '@/views/app/ActivitiesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/activities/:id/delete',
        name: 'activities.delete',
        component: () => import(/* webpackChunkName: "ActivitiesActions" */ '@/views/app/ActivitiesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]